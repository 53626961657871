import React from 'react';
import { motion } from 'framer-motion';
import ThreeScene from './Threejs';

// Tableau de compétences avec des icônes (facultatif)
const skills = [
    { name: 'HTML', icon: 'html5' },
    { name: 'CSS', icon: 'css3-alt' },
    { name: 'JavaScript', icon: 'js' },
    { name: 'PHP', icon: 'php' },
    { name: 'MySQL', icon: 'database' },
    { name: 'React', icon: 'react' },
    { name: 'Laravel', icon: 'laravel' },
    { name: 'Symfony', icon: 'symfony' },
    { name: 'MongoDB', icon: 'database' },
    { name: 'React Native', icon: 'react' },
    { name: 'WordPress', icon: 'wordpress' },
    { name: 'Git', icon: 'git-alt' },
    { name: 'Sass', icon: 'sass' },
    { name: 'Tailwind', icon: 'tailwindcss' }
];

const Aboutme = () => {
    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = 'CV.pdf';
        link.download = 'CV.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const fadeIn = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 }
    };

    return (
        <div className='aboutme sky-container pt-20 pr-5 pl-5 pb-20 md:pr-20 md:pl-20' style={{ position: 'relative', zIndex: 1 }}>
            <ThreeScene />

            <div className='flex flex-col items-start relative z-10'>
                <motion.div
                    className='name w-full md:w-6/12 text-white px-6 py-4 rounded-md shadow-md'
                    initial="hidden"
                    animate="visible"
                    variants={fadeIn}
                    transition={{ duration: 0.5 }}
                >
                    <h1 className='text-4xl md:text-6xl title' id='about'>Fabien Ivaldi</h1>
                    <p className='mt-7 text-2xl md:text-4xl title'>Développeur Web Fullstack</p>
                </motion.div>

                <motion.div
                    className='bigbox text-2xl name w-full md:w-10/12 text-white px-6 py-4 rounded-md shadow-md mt-8'
                    initial="hidden"
                    animate="visible"
                    variants={fadeIn}
                    transition={{ duration: 0.5, delay: 0.3 }}
                >
                    <h1 className='text-4xl mb-4 font-bold title'>Qui suis-je ?</h1>
                    <p className='text-justify'>
                        En tant qu'étudiant à la Web@cademie by Epitech, je suis développeur Fullstack spécialisé dans la création de projets web. Ma passion pour l'apprentissage de nouvelles technologies me permet de répondre efficacement aux divers besoins. Vous pouvez découvrir certains de mes projets dans la section dédiée.
                        <br /><br />
                        Je maîtrise déjà plusieurs outils, tels que Photoshop et Premiere Pro. En dehors du développement, j'ai des hobbies comme jouer aux jeux vidéo et regarder des films et séries. Je nourris également une passion pour l'aéronautique et la science-fiction.
                    </p>
                </motion.div>

                <div className='flex flex-col md:flex-row justify-between w-full mt-8'>
                    <motion.div
                        className='mt-8 text-2xl name w-full md:w-5/12 text-white px-6 py-4 rounded-md shadow-md md:mr-4'
                        initial="hidden"
                        animate="visible"
                        variants={fadeIn}
                        transition={{ duration: 0.5, delay: 0.6 }}
                    >
                        <h1 className='text-4xl mb-6 font-bold title'>Mes compétences</h1>
                        <div className='competences flex flex-wrap'>
                            {skills.map((skill, index) => (
                                <div key={index} className='competence p-2 m-1 bg-blue-500 text-white rounded-md flex items-center'>
                                    {/* Icône (facultatif) */}
                                    {/* <i className={`fab fa-${skill.icon} mr-2`}></i> */}
                                    <span>{skill.name}</span>
                                </div>
                            ))}
                        </div>
                    </motion.div>
                    <motion.div
                        className='mt-8 text-2xl name w-full md:w-5/12 text-white px-6 py-4 rounded-md shadow-md md:ml-4'
                        initial="hidden"
                        animate="visible"
                        variants={fadeIn}
                        transition={{ duration: 0.5, delay: 0.9 }}
                    >
                        <h1 className='text-4xl mb-6 font-bold title'>Mon CV</h1>
                        <div className='flex justify-center items-center h-56'>
                            <button onClick={handleDownload} className='bouton z-10'>
                                <span>Télécharger le fichier</span>
                            </button>
                        </div>
                    </motion.div>
                </div>
            </div>
        </div>
    );
};

export default Aboutme;
