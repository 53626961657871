import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { TextureLoader } from 'three/src/loaders/TextureLoader';

const ThreeScene = () => {
    const mountRef = useRef(null);
    const sphereRef = useRef(null);
    const objRef = useRef(null);
    const starsRef = useRef([]);

    useEffect(() => {
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight, 0.1, 1000);
        const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });

        renderer.setSize(window.innerWidth, window.innerHeight);
        renderer.setPixelRatio(window.devicePixelRatio);  // Adjust for high-DPI screens
        renderer.setClearColor(0x000000, 0);
        renderer.shadowMap.enabled = true;
        mountRef.current.appendChild(renderer.domElement);

        const loader = new OBJLoader();
        loader.load(
            'rocket.obj',
            (object) => {
                objRef.current = object;
                const textureLoader = new THREE.TextureLoader();
                const texture = textureLoader.load('rocket.jpg');

                object.traverse((child) => {
                    if (child instanceof THREE.Mesh) {
                        child.material.map = texture;
                        child.material.needsUpdate = true;
                        child.castShadow = true;
                        child.receiveShadow = true;
                    }
                });
                scene.add(object);

                object.position.set(-6, -2, 2);
                object.scale.set(0.003, 0.003, 0.003);
                object.rotation.set(300, 26, 90);
            },
            (xhr) => {
                console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
            },
            (error) => {
                console.error('Error loading OBJ model:', error);
            }
        );

        const textureLoader = new THREE.TextureLoader();
        const diffuseTexture = textureLoader.load('diffuse.png');
        const bumpTexture = textureLoader.load('bump.png');
        const cloudTexture = textureLoader.load('cloud.png');
        const roughnessTexture = textureLoader.load('roughness.png');

        const geometry = new THREE.SphereGeometry(2, 40, 40);
        const material = new THREE.MeshStandardMaterial({
            map: diffuseTexture,
            bumpMap: bumpTexture,
            bumpScale: 0.1,
            alphaMap: cloudTexture,
            roughnessMap: roughnessTexture,
        });
        const sphere = new THREE.Mesh(geometry, material);
        sphere.castShadow = true;
        sphere.receiveShadow = true;
        sphereRef.current = sphere;

        sphere.position.set(6, 3, 3);
        scene.add(sphere);

        const directionalLight = new THREE.DirectionalLight(0xffffff, 3);
        directionalLight.position.set(5, 5, 5);
        directionalLight.castShadow = true;
        scene.add(directionalLight);

        directionalLight.shadow.mapSize.width = 1024;
        directionalLight.shadow.mapSize.height = 1024;
        directionalLight.shadow.camera.near = 0.1;
        directionalLight.shadow.camera.far = 50;

        const ambientLight = new THREE.AmbientLight(0x404040, 5);
        scene.add(ambientLight);

        const starGeometry = new THREE.BufferGeometry();
        const starMaterial = new THREE.PointsMaterial({ color: 0xffffff, size: 0.2 });
        const starPositions = [];

        for (let i = 0; i < 100; i++) {
            const star = {
                x: (Math.random() - 0.5) * 100,
                y: Math.random() * 50,
                z: (Math.random() - 0.5) * 100,
                velocity: 0.01 + Math.random() * 0.05
            };
            starsRef.current.push(star);
            starPositions.push(star.x, star.y, star.z);
        }

        starGeometry.setAttribute('position', new THREE.Float32BufferAttribute(starPositions, 3));
        const stars = new THREE.Points(starGeometry, starMaterial);
        scene.add(stars);

        camera.position.z = 10;

        const animate = () => {
            requestAnimationFrame(animate);

            if (objRef.current) {
                objRef.current.rotation.z += 0.0004;
            }
            sphere.rotation.y += 0.0005;
            sphere.position.y = window.scrollY * 0.0002;

            const positions = stars.geometry.attributes.position.array;
            for (let i = 0; i < starsRef.current.length; i++) {
                const star = starsRef.current[i];
                star.x -= star.velocity * Math.cos(Math.PI / 4);
                star.y -= star.velocity * Math.sin(Math.PI / 4);
                star.z -= star.velocity * 0.1;
                if (star.x < -50) {
                    star.x = 50;
                } else if (star.x > 50) {
                    star.x = -50;
                }
                if (star.y < -50) {
                    star.y = 50;
                    star.x = (Math.random() - 0.5) * 100;
                    star.z = (Math.random() - 0.5) * 100;
                    star.velocity = 0.01 + Math.random() * 0.05;
                }

                positions[i * 3] = star.x;
                positions[i * 3 + 1] = star.y;
                positions[i * 3 + 2] = star.z;
            }
            stars.geometry.attributes.position.needsUpdate = true;

            renderer.render(scene, camera);
        };

        animate();

        const handleResize = () => {
            const width = mountRef.current.clientWidth;
            const height = mountRef.current.clientHeight;

            renderer.setSize(width, height);
            camera.aspect = width / height;
            camera.updateProjectionMatrix();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            mountRef.current.removeChild(renderer.domElement);
        };
    }, []);

    return <div ref={mountRef} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '70%' }} />;
};

export default ThreeScene;
