import React from 'react';

const Logo = () => {
    return (
        <div className='flex text-lg items-center space-x-8'>
            <img src='./logo.png' className="w-16 h-16" alt="logo" />
            <p>FABIEN IVALDI</p>
        </div>

    );
};

export default Logo;