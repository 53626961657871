import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        emailjs.send('service_c8r11xk', 'template_eh8tfzg', formData, 'SLC8t0mFyDfeoWSVJ')
            .then((response) => {
                console.log('Email sent successfully:', response.status, response.text);

                setFormData({
                    name: '',
                    email: '',
                    message: '',
                });
            })
            .catch((error) => {
                console.error('Error sending email:', error);
            });
    };

    return (
        <div className='contact pt-20 pr-8 pl-8 md:pr-20 md:pl-20 pb-20' id='contact'>
            <h1 className='title mt-10 text-4xl mb-6 font-bold text-center'>Me contacter</h1>

            <form onSubmit={handleSubmit} className='flex flex-col max-w-xl mx-auto'>
                <div className='mb-4'>
                    <label htmlFor="name" className='block mb-2 text-sm font-bold text-white'>Nom:</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className='w-full px-3 py-2 border rounded shadow focus:outline-none focus:ring-2 focus:ring-blue-500'
                        required
                    />
                </div>
                <div className='mb-4'>
                    <label htmlFor="email" className='block mb-2 text-sm font-bold text-white'>Email:</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className='w-full px-3 py-2 border rounded shadow focus:outline-none focus:ring-2 focus:ring-blue-500'
                        required
                    />
                </div>
                <div className='mb-6'>
                    <label htmlFor="message" className='block mb-2 text-sm font-bold text-white'>Message:</label>
                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        className='w-full px-3 py-2 border rounded shadow focus:outline-none focus:ring-2 focus:ring-blue-500'
                        rows="5"
                        required
                    ></textarea>
                </div>
                <button
                    type="submit"
                    className='w-full py-2 px-4 bg-blue-600 text-white font-bold rounded hover:bg-blue-700 transition duration-300'
                >
                    Envoyer
                </button>
            </form>
        </div>
    );
};

export default ContactForm;
