import './Loading.css';

const Loading = () => {
   

    return (
        <div className='flex flex-col'>
            <div className='flex justify-center'>
                <div className="loader"> </div>
            </div>
            <p className=' text text-white'>Loading</p>


        </div>
    )
}
export default Loading;
