import React, { useState } from 'react';
import './styles.css'; // Assurez-vous d'importer un fichier CSS pour les styles supplémentaires
import { useInView } from 'react-intersection-observer';

const Projects = () => {
    const [expandedBox, setExpandedBox] = useState(null);

    // Utilisation de react-intersection-observer pour détecter la visibilité des éléments
    const { ref, inView } = useInView({
        triggerOnce: true, // Détecter une seule fois lorsqu'ils entrent dans la vue
        threshold: 0 // Détecter lorsque l'élément devient entièrement visible
    });

    const handleClick = (id) => {
        setExpandedBox(expandedBox === id ? null : id);
    };

    const projects = [
        { id: 1, img: '/projet2.png', title: 'My_CINEMA', text: 'Premier gros projets qui est un site web pour administrer les cinémas (gérer les abonnements, les séances de film, ... )' },
        { id: 2, img: '/projet3.png', title: 'My_H5AI', text: 'Créer un explorateur de fichier dans un navigateur permettant de visualiser les fichiers textes inclus, codé en PHP' },
        { id: 3, img: '/projet1.png', title: 'Battleship', text: 'Le but était de débuguer un jeu de bataille naval codé en Javascript' },
        { id: 4, img: '/projet4.png', title: 'My_quiz', text: 'Sur ce projet, on devait faire un quiz sur différents themes avec le framework symphony 7' }
    ];

    return (
        <div className='projectbg pt-20 pr-20 pl-20 pb-20'>
            <h1 id='projects' className='title mt-10 text-4xl mb-14 font-bold text-white text-center'>Mes Projets</h1>
            <div className='flex justify-between flex-wrap'>
                {projects.map((project) => (
                    <div
                        key={project.id}
                        className={`box p-6 mb-14 text-center text-white ${expandedBox === project.id ? 'expanded' : ''} ${inView ? 'appear' : ''}`}
                        onClick={() => handleClick(project.id)}
                        ref={ref} // Référence pour react-intersection-observer
                    >
                        {project.img && <img src={project.img} alt={project.title} />}
                        <p className='project mt-8 mb-4'>{project.title}</p>
                        <hr />
                        {expandedBox === project.id && (
                            <div className='additional-text mt-4'>
                                <p>{project.text}</p>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Projects;
