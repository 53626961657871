import React from 'react';

const Footer = () => {
    return (
        <div>


            <footer class=" shadow  dark:bg-gray-800">
                <div class="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
                    <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2024 Tout droit réserver.
                    </span>
                    <ul class="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
                        <li>
                            <a href="#" class="hover:underline me-4 md:me-6">Home</a>
                        </li>
                        <li>
                            <a href="#about" class="hover:underline me-4 md:me-6">A propos</a>
                        </li>
                        <li>
                            <a href="#projects" class="hover:underline me-4 md:me-6">Projets</a>
                        </li>
                        <li>
                            <a href="#contact" class="hover:underline">Me contacter</a>
                        </li>
                    </ul>
                </div>
            </footer>

        </div>
    );
};

export default Footer;