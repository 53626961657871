import React, { useState } from 'react';
import Logo from './Logo';

const Headers = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <header className=" header  ">
            <div className='all flex justify-between pr-8 pt-4 pb-4 items-center z-10 shadow-md' id='home'>
            <div className='logo ml-16'>
                <Logo />
            </div>
            <button
                className="md:hidden text-gray-500 focus:outline-none"onClick={toggleMenu}>
                <svg className="w-6 h-6 mr-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                </svg>
            </button>
            <nav className={`link ${isOpen ? 'block' : 'hidden'} md:block md:flex text-lg space-y-4 md:space-y-0 md:space-x-10 mr-16`}>
                <a href="#" className=" headerbtn transition duration-300 ease-in-out hover:bg-blue-800 p-1 block md:inline-block">HOME</a>
                    <a href="#about" className=" headerbtn transition duration-300 ease-in-out hover:bg-blue-800 p-1 block md:inline-block">A PROPOS</a>
                    <a href="#projects" className="headerbtn transition duration-300 ease-in-out hover:bg-blue-800 p-1 block md:inline-block">PROJETS</a>
                    <a href="#contact" className="headerbtn transition duration-300 ease-in-out hover:bg-blue-800 p-1 block md:inline-block">CONTACT</a>
                </nav>
            </div>
            <div className='space'></div>
        </header>
    );
};

export default Headers;
