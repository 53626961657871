import { useEffect, useState } from "react";

import Headers from './components/Headers';
import Aboutme from './components/aboutme';
import { motion, useScroll } from 'framer-motion';
import './App.css';
import Projects from './components/Projects';
import Footer from './components/Footer';
import ContactForm from './components/Contacts';
import Loading from './components/Loading';

function App() {
  const { scrollYProgress } = useScroll();
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setTimeout(() => setLoading(false), 2000)
  }, [])
  if (loading) {
    return <Loading />
  }

  return (
    <div>
    <div>
        <motion.div
          style={{
            position: 'fixed',
            top: 95,
            left: 0,
            right: 0,
            height: '7px',
            backgroundColor: '#005176',
            transformOrigin: '0%',
            scaleX: scrollYProgress,
            zIndex: 10
          }}
        />
      </div>
      

        <Headers />
        
        <Aboutme />

      <Projects />
      <ContactForm />
  <Footer/>
      
    </div>
  );
}

export default App;
